import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Link from '../components/gatsbylink'
import MeinSEO from '../components/seo'
import { motion } from 'framer-motion'

//import Motto from '../components/motto'



const TextDe = () => (
    <>
    
    <Box>
        <Heading my="3" fontWeight="600" fontSize={["2xl","4xl","4xl"]}  py="0">
            K.O.M. 
        </Heading>
        Kulturnetz Oberes Mittelrheintal e.V.<br/>        Ansprechpartnern:innen:<br/> René Broich,<br/> Prof. Dr. Susanne Enderwitz,<br/> Katrin Gloggengießer
        <Text py="3" >
           kontakt@kulturnetz.oberes-mittelrheintal.org
           
   
        </Text>
        <Link href="https://kulturnetz-oberes-mittelrheintal.org"><Text fontWeight="600">https://kulturnetz-oberes-mittelrheintal.org</Text></Link>
        
    </Box>
    </>
   )


const Kontakt =()=>{
   
    return(
        <motion.div
        initial={{opacity:0,y:0,paddingBottom:"40px"}}
        animate={{opacity:1,y:10}}
        transition={{duration:.6}}
        >
        <Box p="8">
             <MeinSEO title='Kontakt' description='Verluste. Ausstellungsparcours.' keywords="Holocaust" />
          
         
        <TextDe/>
      
         
        </Box>
        </motion.div>
        

    )
} 
export default Kontakt

